export const countries: string[] = [
    'Afghanistan',
    'Aland Islands',
    'Albania',
    'Algeria',
    'American Samoa',
    'Andorra',
    'Angola',
    'Anguilla',
    'Antarctica',
    'Antigua and Barbuda',
    'Argentina',
    'Armenia',
    'Aruba',
    'Ascension Island',
    'Australia',
    'Austria',
    'Azerbaijan',
    'Bahamas',
    'Bahrain',
    'Bangladesh, Peoples Rep. of',
    'Barbados',
    'Belarus',
    'Belgium',
    'Belize',
    'Benin, Peoples Rep. of',
    'Bermuda',
    'Bhutan',
    'Bolivia',
    'Bonaire',
    'Bosnia-Herzegovina',
    'Botswana',
    'Brazil',
    'British Indian Ocean Territory',
    'Brunei',
    'Bulgaria',
    'Burkina Faso',
    'Burundi',
    'Cambodia',
    'Cameroon, United Rep. of',
    'Canada',
    'Cape Verde Islands',
    'Cayman Islands',
    'Central African Republic',
    'Chad',
    'Chile',
    'China, Peoples Rep. of',
    'Christmas Island',
    'Cocos Islands',
    'Colombia',
    'Comoros',
    'Congo',
    'Congo, The Democratic Republic of the',
    'Cook Islands',
    'Costa Rica',
    'Croatia',
    'Cuba',
    'Curacao',
    'Cyprus',
    'Czech Republic',
    'Denmark',
    'Diego Garcia',
    'Djibouti',
    'Dominica',
    'Dominican Republic',
    'Ecuador',
    'Egypt, Arab Rep. of',
    'El Salvador',
    'Equatorial Guinea',
    'Eritrea',
    'Estonia',
    'Ethiopia',
    'Falkland Islands',
    'Faeroe Islands',
    'Fiji Islands',
    'Finland',
    'France',
    'French Guiana',
    'French Polynesia (Moorea &amp; Tahiti',
    'French Southern Territories',
    'Gabon Republic',
    'Gambia',
    'Georgia',
    'Germany',
    'Ghana',
    'Gibraltar',
    'Greece',
    'Greenland (Kalaallit Nunaat)',
    'Grenada',
    'Guadeloupe',
    'Guam',
    'Guantanamo Bay (U.S. Naval Base)',
    'Guatemala',
    'Guernsey',
    'Guinea Bissau',
    'Guinea, Peoples Rev. Rep.',
    'Guyana',
    'Haiti',
    'Heard Island and McDonald Island',
    'Honduras',
    'Hong Kong',
    'Hungary',
    'Iceland',
    'India',
    'Indonesia',
    'Iran',
    'Iraq',
    'Ireland, Rep. of',
    'Isle of Man',
    'Israel',
    'Italy',
    'Ivory Coast Rep. of',
    'Jamaica',
    'Japan',
    'Jersey',
    'Jordan',
    'Kazakhstan',
    'Kenya, Rep of.',
    'Kiribati',
    'Kuwait',
    'Kyrgyzstan',
    'Laos',
    'Latvia',
    'Lebanon',
    'Lesotho',
    'Liberia',
    'Libya',
    'Liechtenstein',
    'Lithuania',
    'Luxembourg',
    'Macao',
    'Macedonia',
    'Madagascar',
    'Malawi',
    'Malaysia',
    'Maldives, Rep. of',
    'Mali',
    'Malta',
    'Marshall Islands',
    'Martinique',
    'Mauritania',
    'Mauritius',
    'Mayotte',
    'Mexico',
    'Micronesia, Fed. States of',
    'Moldova',
    'Monaco',
    'Mongolia',
    'Montenegro',
    'Montserrat',
    'Morocco, Kingdom of',
    'Mozambique',
    'Myanmar',
    'Namibia',
    'Nepal',
    'Netherlands',
    'Netherlands Antilles',
    'New Caledonia',
    'New Zealand',
    'Nicaragua',
    'Niger Republic',
    'Nigeria, Fed. Rep. of',
    'Niue',
    'Norfolk Island',
    'North Mariana Islands',
    'North Korea',
    'Norway',
    'Oman',
    'Pakistan',
    'Palau',
    'Palestinian Territory',
    'Panama, Rep. of',
    'Papua New Guinea',
    'Paraguay',
    'Peru',
    'Philippines',
    'Pitcairn',
    'Poland',
    'Portugal',
    'Puerto Rico',
    'Qatar',
    'Reunion',
    'Romania',
    'Russia',
    'Rwanda',
    'Samoa',
    'Saipan',
    'San Marino',
    'Sao Tome and Principe',
    'Saudi Arabia',
    'Senegal Republic',
    'Serbia',
    'Seychelles',
    'Sierra Leone',
    'Singapore, Rep. of',
    'Slovakia',
    'Slovenia',
    'Solomon Islands',
    'Somolia',
    'South Africa',
    'South Georgia',
    'South Korea',
    'Spain',
    'Sri Lanka, Dem. Soc. Rep. of',
    'St. Barthelemy',
    'St. Helena',
    'St. Kits',
    'St. Pierre and Miquelon',
    'St. Martin',
    'St Vincent and the Grenadines',
    'Sudan',
    'Suriname',
    'Svalbard and Jan Mayen',
    'Swaziland',
    'Sweden',
    'Switzerland',
    'Syria',
    'Tadzhikistan',
    'Taiwan',
    'Tanzania',
    'Thailand',
    'Timor-Leste',
    'Togo, Rep. of',
    'Tokelau',
    'Tonga Islands',
    'Trinidad and Tobago',
    'Tunisia',
    'Turkey',
    'Turkmenistan',
    'Turks and Caicos Islands',
    'Tuvalu',
    'Uganda',
    'Ukraine',
    'United Arab Emirates',
    'United Kingdom',
    'United States',
    'United States Minor Outlying Islands',
    'Uruguay',
    'Uzbekistan',
    'Vanuatu',
    'Vatican City',
    'Venezuela',
    'Vietnam',
    'Virgin Islands, British',
    'Virgin Islands, US',
    'Wallis and Futuna',
    'Western Sahara',
    'Yemen Arab Republic',
    'Zaire, Rep. of',
    'Zambia',
    'Zimbabwe'
];

// tslint:disable-next-line: no-empty-interface
export interface IWineClubCountriesData {}