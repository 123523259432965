import * as Msdyn365 from '@msdyn365-commerce/core';
import { IDataServiceRequest } from '@msdyn365-commerce/retail-proxy';
import { createWineClubSignUpInput } from './DataActionExtension.g';

/**
 * WineClub Input Action
 */

export class WineClubInput implements Msdyn365.IActionInput {
    public input: IDataServiceRequest;

    constructor(input: IDataServiceRequest) {
        this.input = input;
    }

    public shouldCacheOutput = () => { return false; };
    public getCacheKey = () => { return 'IWineClubInput'; };
    public getCacheObjectType = () => 'IWineClubInput';
    public dataCacheType = (): Msdyn365.CacheType => 'application';
}

export interface IWineClubInputData {
    result: string;
    Message: string;
}

export interface IWineClubInfo {
    wineClubId: string;
    customerId: string;
    isGifted: boolean;
    deliveryPreference: string;
    addressFirstName: string;
    addressLastName: string;
    addressEmail: string;
    addressPhone: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: string;
    zipcode: string;
    country: string;
}

export const createWineClubInput = (inputData: IWineClubInfo): WineClubInput => {
    return new WineClubInput(
        createWineClubSignUpInput({
            WineClubId: inputData.wineClubId,
            AddressEmail: inputData.addressEmail,
            AddressFirstName: inputData.addressFirstName,
            AddressLastName: inputData.addressLastName,
            AddressLine1: inputData.addressLine1,
            AddressLine2: inputData.addressLine2,
            AddressPhone: inputData.addressPhone,
            City: inputData.city,
            Country: inputData.country,
            CustomerId: inputData.customerId,
            DeliveryPreference: inputData.deliveryPreference,
            IsGifted: inputData.isGifted,
            State: inputData.state,
            Zipcode: inputData.zipcode
        })
    );
};

export async function wineClubAction(input: WineClubInput, ctx: Msdyn365.IActionContext): Promise<IWineClubInputData> {
    return input.input.execute(ctx);
}

export const IWineClubAction = Msdyn365.createObservableDataAction({
    action: <Msdyn365.IAction<IWineClubInputData>>wineClubAction,
    input: createWineClubInput
});